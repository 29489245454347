// src/components/headMetadata/headMetadata.js

import React from "react"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

// import { graphql, useStaticQuery } from "gatsby"

// import { useLocale } from "./I18nContext"
// import gatsbyIcon from "../assets/gatsby-icon.png"

const HeadMetadata = ({ title }) => {
  const { name } = useSiteMetadata()

  // const {
  //   site: {
  //     siteMetadata: { siteUrl, title, twitter },
  //   },
  // } = useStaticQuery(graphql`
  //   query SiteMetadata {
  //     site {
  //       siteMetadata {
  //         siteUrl
  //         title
  //         twitter
  //       }
  //     }
  //   }
  // `)

  const locale = "de"

  return (
    <Helmet defer={false} defaultTitle={name} titleTemplate={`%s | ${title}`}>
      <html lang={locale} />
      <title>{name}</title>
      {/* <link rel="stylesheet" href="https://use.typekit.net/cdr7eph.css"/> */}
      {/* <link rel="stylesheet" href="//basehold.it/12"></link> */}

      {/* <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta name="docsearch:version" content="2.0" /> */}
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/imgs/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/imgs/favicons/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/imgs/favicons/favicon-16x16.png"
      />
      {/* <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={`${siteUrl}${gatsbyIcon}`} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" /> */}

      {/* <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitter} /> */}
    </Helmet>
  )
}

export default HeadMetadata;
